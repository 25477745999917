document.addEventListener("DOMContentLoaded", () => {
  const modalTriggers = document.querySelectorAll("[data-activate-modal]")
  const modalClosers = document.querySelectorAll("[data-close-modal]")

  let autoActivatedModal = false

  modalTriggers.forEach( (trigger) => {
    const modalId = trigger.dataset.activateModal
    const autoActivate = trigger.dataset.autoActivate

    const modal = document.getElementById(modalId);

    trigger.addEventListener( "click", (event) => {
      event.preventDefault()
      modal.classList.add("is-active")
    })
    if (autoActivate) {
      if (autoActivatedModal) {
        console.error(`Modal ${autoActivatedModal} was already auto-activated. Forbidden to activate another (${modalId})`)
      }
      else {
        autoActivatedModal = modalId
        modal.classList.add("is-active")
      }
    }
  })

  modalClosers.forEach( (closer) => {
    const modalId = closer.dataset.closeModal
    const modal = document.getElementById(modalId);
    closer.addEventListener( "click", (event) => {
      event.preventDefault()
      modal.classList.remove("is-active")
    })
  })

  document.onkeydown = function(event) {
    if (event.key === "Escape" || event.key === "Esc") {
      const openModals = document.querySelectorAll(".modal.is-active")
      openModals.forEach( (modal) => {
        modal.classList.remove("is-active")
      })
    }
  }
})
