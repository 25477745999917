// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
/* Removing these as we aren't using them, but leaving the invocation
 * in case we later want to
require("@rails/activestorage").start()
require("channels")
*/

import "@fortawesome/fontawesome-free/css/all.css"
import "./application.scss"
import "../bulma_file_inputs.js"
import "../bulma_modal.js"
import "../bulma_click_to_copy.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
