document.addEventListener("DOMContentLoaded", () => {
  const fileControls = document.querySelectorAll(".file.has-name")
  fileControls.forEach( (fileControl) => {
    const input = fileControl.querySelector("input[type=file]")
    input.onchange = () => {
      if (input.files.length > 0) {
        const fileName = fileControl.querySelector('.file-name');
        fileName.textContent = input.files[0].name;
      }
    }
  })
})
