document.addEventListener("DOMContentLoaded", () => {
  const clickToCopyLinks = document.querySelectorAll(".click-to-copy")

  clickToCopyLinks.forEach(link => {
    link.addEventListener("click", event => {
      event.preventDefault()

      const clickedLink = event.currentTarget

      let linkText = clickedLink.innerText
      if (clickedLink.dataset["textToCopy"]) {
        linkText = clickedLink.dataset["textToCopy"]
      }

      const tmpInput = clickedLink.appendChild(document.createElement("input"))

      tmpInput.value = linkText
      tmpInput.focus()
      tmpInput.select()

      document.execCommand("copy")
      tmpInput.parentNode.removeChild(tmpInput)

      clickedLink.setAttribute("data-tooltip", "Copied!")

      setTimeout(() => {
        clickedLink.removeAttribute("data-tooltip")
      }, 2000)
    })
  })
})
